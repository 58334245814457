<template>
  <div
    v-if="url"
    class="video"
    :class="{ 'video--rounded': rounded && !full }"
    v-observe="autoplayInViewport"
    @elementEntered="playVideo()"
    @elementLeft="pauseVideo()"
  >
    <video
      ref="video"
      class="video__video"
      :class="{ 'video__video--rounded': rounded && !full }"
      :src="url"
      :controls="showControls"
      :muted="isMuted"
      :loop="loop"
      :playsinline="autoplay"
    ></video>
    <div
      v-if="poster && !videoStarted"
      class="video__poster"
      :class="{ 'video__poster--rounded': rounded && !full }"
    >
      <img :src="poster" alt="" loading="lazy" />
    </div>
    <div
      v-if="!videoStarted"
      class="video__play"
      :class="{ 'video__play--simple': smallIcon }"
      @click="playVideo()"
    >
      <span v-if="!smallIcon"> Abspielen </span>
      <div
        class="video__play-inner"
        :class="{ 'video__play-inner--small': smallIcon }"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const props = defineProps({
  url: {
    type: String,
    default: "",
  },
  poster: {
    type: String,
    default: "",
  },
  controls: {
    type: Boolean,
    default: false,
  },
  autoplay: {
    type: Boolean,
    default: false,
  },
  autoplayInViewport: {
    type: Boolean,
    default: false,
  },
  mute: {
    type: Boolean,
    default: false,
  },
  noPlayback: {
    type: Boolean,
    default: false,
  },
  smallIcon: {
    type: Boolean,
    default: false,
  },
  full: {
    type: Boolean,
    default: false,
  },
  loop: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
});

const videoStarted = ref(false);
const autoPaused = ref(false);
const pausedByUser = ref(false);
const video = ref(null);

const showControls = computed(() => {
  if (!props.controls || !videoStarted.value) {
    return false;
  }
  return true;
});
const isMuted = computed(() => {
  if (props.mute || props.autoplay || props.autoplayInViewport) {
    return true;
  }
});
const isPlaying = computed(() => {
  if (
    video.value &&
    video.value.currentTime > 0 &&
    !video.value.paused &&
    !video.value.ended
  ) {
    return true;
  }

  return false;
});

const playVideo = () => {
  if (video.value && !props.noPlayback && !pausedByUser.value) {
    autoPaused.value = false;
    video.value.play();
    videoStarted.value = true;
  }
};
const pauseVideo = () => {
  if (video.value) {
    autoPaused.value = true;
    video.value.pause();
  }
};

onMounted(() => {});
</script>

<style lang="scss">
.video {
  position: relative;
  background: $color-black;

  &--rounded {
    border-radius: $radius-large;
  }
}

.video__video {
  width: 100%;

  &--rounded {
    border-radius: $radius-large;
  }
}

.video__poster {
  position: absolute;
  inset: 0;

  &--rounded {
    border-radius: $radius-large;

    img {
      border-radius: $radius-large !important;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0;
  }
}

.video__play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: $spacing-sm;
  background: $color-white;
  color: $color-black;
  border-radius: $radius-small;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: $color-black;
    color: $color-white;
  }

  &--simple {
    background: transparent;
    padding: 0;
  }

  span {
    margin-right: $spacing-xs;
  }
}

.video__play-inner {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 12px;
  border-color: transparent transparent transparent currentColor;

  &--small {
    border-width: 5px 0 5px 10px;
  }
}
</style>
